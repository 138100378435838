import React, { useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Header from '../layouts/Header/Header';
import Footer from '../layouts/Footer/Footer';
import HomePage from '../pages/HomePage/HomePage';
import DemoPage from '../pages/DemoPage/DemoPage';
// import { PoliciesCookies, PoliciesPrivacy, PoliciesTerms } from '../pages/Policies/Policies';

export default function AppRoutes() {
    return (
        <BrowserRouter>
            <Header />
            <main  >
                <Routes>
                    <Route path="/contact-us" element={<HomePage />} />
                    <Route path="/" element={<DemoPage />} />

                    {/* <Route path="/policies/terms-and-conditions" element={<PoliciesTerms />} />
                    <Route path="/policies/privacy-policy" element={<PoliciesPrivacy />} />
                    <Route path="/policies/cookies-policy" element={<PoliciesCookies />} /> */}
                </Routes>
            </main>
            {/* <BannerCookie /> */}
            <Footer />
        </BrowserRouter>
    );
}
