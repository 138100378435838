import React, { useContext, useEffect } from 'react';
import styles from "./Header.module.css";
import { NavLink } from 'react-router-dom';
import { GlobalContext } from '../../context/globalContext';

const Header: React.FC = () => {
    const { isDark } = useContext(GlobalContext);

    return (
        <header className={`${styles.headerContainer} fixed top-0 left-0 font-main-sans text-xl ${isDark ? "text-white " : "text-black"} font-semibold opacity-70 z-[999999] w-full px-6 pt-6 flex justify-between`}>
            <NavLink to="/" className=''>iXiM</NavLink>
            <NavLink to="/contact-us" className='hover:underline'>Contact Us</NavLink>
        </header>
    );
};

export default Header;
