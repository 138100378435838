import React, { useContext, useEffect, useRef, useState } from 'react';
// import { Canvas } from '@react-three/fiber';
// import PlyModel from '../../components/Models/PlyModel';
import { generateModel, generateModelLight } from '../../api/demo';
import Spinner from '../../components/Spinner/Spinner';
import * as SPLAT from 'gsplat';
import styles from "./DemoPage.module.css";
import { GlobalContext } from '../../context/globalContext';

const DemoPage: React.FC = () => {
    // TO DISPLAY
    const [modelPath, setModelPath] = useState("https://replicate.delivery/pbxt/UvKKgNj9mT7pIVHzwerhcjkp5cMH4FS5emPVghk2qyzMRwUSA/gradio_output.ply");
    // const [modelPath, setModelPath] = useState("https://replicate.delivery/pbxt/UvKKgNj9mT7pIVHzwerhcjkp5cMH4FS5emPVghk2qyzMRwUSA/gradio_output.ply");
    const [videoPath, setVideoPath] = useState("https://replicate.delivery/pbxt/UYQDuTakJJYBPtVUbLA8l7ddav3eozIf8xRle0zlefUQJCmSC/gradio_output.mp4");
    // const [videoPath, setVideoPath] = useState("");

    // TO FETCH
    const [prompt, setPrompt] = useState("a dog");
    const [imgUrl, setImgUrl] = useState("https://images.rawpixel.com/image_png_800/cHJpdmF0ZS9sci9pbWFnZXMvd2Vic2l0ZS8yMDIzLTA4L3Jhd3BpeGVsb2ZmaWNlMThfaGFwcHlfc21pbGluZ19nb2xkZW5fcmV0cml2ZXJfcHVwcHlfb25fd2hpdGVfYl8xOTAzYWI3Ni04NjQzLTQxNzYtODY3ZS01MjUxNDk1MGExNTMucG5n.png");

    // LOCAL STATES
    const [isGenerating, setIsGenerating] = useState(false);
    const [isVideo, setIsVideo] = useState(false);
    const [message, setMessage] = useState("");

    // CONTEXT
    const { setIsDark, isDark } = useContext(GlobalContext);

    async function handleGenerateModel(e: React.FormEvent) {
        e.preventDefault();
        if (!(prompt && imgUrl)) {
            setMessage("Both prompt and image URL are required.");
            return;
        }

        try {
            // const response = await generateModelLight({ imgUrl });
            setIsGenerating(true);
            const response = await generateModel({ prompt, imgUrl });
            if (response) {
                const data = await response.json();
                console.log(data);
                setModelPath(data.ply_url);
                setVideoPath(data.mp4_url);
                setIsGenerating(false);

            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsGenerating(false);
        }
    }

    // useEffect(() => {
    //     if (isVideo) {
    //         setIsDark(true);
    //     }
    // }, [isVideo]);

    return (
        <div className='w-screen h-screen bg-white'>
            <div
                className='fixed top-[50px] right-[50vw] translate-x-[50%] flex gap-3 items-center text-main hover:cursor-pointer z-50 opacity-90'
                onClick={() => { setIsVideo(!isVideo); setIsDark(!isDark); }}
            >
                <p>PLY</p>
                <div className='border border-main rounded-full w-14 flex flex-col'>
                    <div className={`bg-main h-5 w-5 m-1 rounded-full ${isVideo ? "self-end" : "self-start"}`}></div>
                </div>
                <p>Video</p>
            </div>
            <div className={`${styles.formContainer} fixed bottom-0 left-0 w-full p-4 md:p-10 z-50 flex flex-col items-center gap-2`}>
                <form
                    onSubmit={handleGenerateModel}
                    className='flex gap-2 w-full flex-col md:flex-row'
                    onChange={() => { setMessage(""); }}
                >
                    <label className='input input-bordered flex items-center gap-2 h-10 sm:h-auto bg-transparent border-main text-black md:max-w-[30vw]'>
                        {/* <label className='input input-bordered flex items-center gap-2 sm:max-w-[30%] h-10 sm:h-auto bg-transparent border-main text-black'> */}
                        <span className='opacity-35 text-black'>Prompt</span>
                        <input className="bg-transparent w-full " type="text" value={prompt} onChange={(e) => { setPrompt(e.target.value); }} />
                        <span className="text-main hover:cursor-pointer opacity-50 text-xl" onClick={() => { setPrompt(""); }}>✕</span>
                    </label>
                    <label className='input input-bordered flex items-center gap-2 w-full h-10 sm:h-auto bg-transparent border-main text-black'>
                        <span className='opacity-35 teblack' style={{ whiteSpace: "nowrap" }}>Image URL</span>
                        <input className="w-full" type="text" value={imgUrl} onChange={(e) => { setImgUrl(e.target.value); }} />
                        <span className="text-main hover:cursor-pointer opacity-50 text-xl" onClick={() => { setImgUrl(""); }}>✕</span>
                    </label>
                    <button className="btn px-10 h-10 min-h-0 md:min-h-[3rem] md:h-auto " type="submit">{isGenerating ? <Spinner /> : "Create"}</button>
                </form>
                <p className='text-main'>{message ? message : ""}</p>
            </div>

            {isVideo ?
                <video className='max-w-screen max-h-screen w-full h-full' controls>
                    <source src={videoPath} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                :
                <SplatConverter modelUrl={modelPath} key={modelPath} />

                // <Canvas>
                //     <ambientLight intensity={0.5} />
                //     <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
                //     <pointLight position={[-10, -10, -10]} />
                //     <PlyModel modelUrl={modelPath} />
                // </Canvas>
            }


        </div>
    );
};

export default DemoPage;


const SplatConverter = ({ modelUrl }: { modelUrl: string; }) => {
    const canvasRef = useRef(null);
    const progressDialogRef = useRef<HTMLDialogElement | null>(null); const progressIndicatorRef = useRef(null);
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!canvasRef.current) return;

        const canvas = canvasRef.current;
        const progressDialog = progressDialogRef.current;
        const progressIndicator = progressIndicatorRef.current;

        const renderer = new SPLAT.WebGLRenderer(canvas);
        const scene = new SPLAT.Scene();
        const camera = new SPLAT.Camera();
        const controls = new SPLAT.OrbitControls(camera, canvas);



        const format = ""; // Adjust as necessary

        const main = async () => {
            await SPLAT.PLYLoader.LoadAsync(modelUrl, scene, (progress) => {
                setProgress(progress * 100);
            }, format);

            if (progressDialog) {
                progressDialog.close(); // Adjust based on how you implement dialog functionality in React
            }
            // scene.saveToFile("converted.splat");

            const frame = () => {
                controls.update();
                renderer.render(scene, camera);
                requestAnimationFrame(frame);
            };

            requestAnimationFrame(frame);
        };

        main();

        // Cleanup function to remove event listeners, etc.
        return () => {
            // Perform necessary cleanup
        };
    }, []);

    return (
        <div>
            <canvas ref={canvasRef} style={{ width: '100%', height: '100vh', transform: 'scaleY(-1)' }} />
            <dialog ref={progressDialogRef} open={progress < 100}>
                <progress ref={progressIndicatorRef} max="100" value={progress}></progress>
            </dialog>
        </div>
    );
};

const SplatComponent = () => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        let frameId: any;

        // Initialize the scene, camera, and renderer
        const scene = new SPLAT.Scene();
        const camera = new SPLAT.Camera();
        const renderer = new SPLAT.WebGLRenderer();

        if (containerRef.current) {
            containerRef.current.appendChild(renderer.canvas);
        }

        const controls = new SPLAT.OrbitControls(camera, renderer.canvas);

        // Load your 3D model or scene
        const loadScene = async () => {
            const url = "https://replicate.delivery/pbxt/h6NmhvJZEv6qFp4hmjcXbeTrYOcKQXLbZQoW7LP4srcwqFOJA/gradio_output.ply";
            await SPLAT.Loader.LoadAsync(url, scene, () => { });
        };

        loadScene();

        // Animation loop
        const animate = () => {
            frameId = requestAnimationFrame(animate);
            controls.update();
            renderer.render(scene, camera);
        };

        animate();

        // Cleanup function
        return () => {
            if (frameId) {
                cancelAnimationFrame(frameId);
            }

            if (containerRef.current && renderer.canvas) {
                containerRef.current.removeChild(renderer.canvas);
            }
            // Additional cleanup like disposing of the scene, renderer, or controls if needed
        };
    }, []); // Empty dependency array ensures this effect runs only once

    return <div ref={containerRef} style={{ width: '100%', height: '100vh' }} />;
};