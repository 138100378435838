import { API_ENDPOINT } from ".";

export const generateModel = async ({ prompt, imgUrl }: { prompt: string, imgUrl: string; }) => {
    try {
        const response = await fetch(`${API_ENDPOINT}/process/`, {
            method: "POST",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                seed: 6,
                prompt,
                input_image: imgUrl,
                negative_prompt: "ugly, blurry, pixelated obscure, unnatural colors, poor lighting, dull, unclear, cropped, lowres, low quality, artifacts, duplicate"
            }),
        });
        return response;
    } catch (err) {
        console.log(err);
    }
};
export const generateModelLight = async ({ imgUrl }: { imgUrl: string; }) => {
    try {
        const response = await fetch(`${API_ENDPOINT}/process_light/`, {
            method: "POST",
            credentials: "include",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                image_url: imgUrl,
            }),
        });
        return response;
    } catch (err) {
        console.log(err);
    }
};
